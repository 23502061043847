import { Component, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { combineLatest } from 'rxjs';
import { IOnsiteAuthService } from 'src/app/auth/onsite-auth-service.interface';
import { RoutingService } from 'src/app/services/routing/routing.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit  {

  year: number = new Date().getFullYear();

  public isViewingChangeComplexSchedulePage = false;
  public isScheduleAdminVisible = false;
  public isDashboardAdminVisible = false;
  public isOnsiteApprovalAdminVisible = false;

  constructor(@Inject('OnsiteAuthService') private onsiteAuthService: IOnsiteAuthService,
              @Inject('RoutingService') private routingService: RoutingService,
              private renderer: Renderer2) { }

    ngAfterViewInit() {
      this.onsiteAuthService.hasScheduleAdminAccess$().subscribe((isAdmin: boolean) => this.isScheduleAdminVisible = isAdmin);
      this.onsiteAuthService.hasOnsiteApprovalAdminAccess$().subscribe((isAdmin: boolean) => this.isOnsiteApprovalAdminVisible = isAdmin);
  
      this.routingService.isViewingChangeComplexSchedulePage$
        .subscribe(isViewing => this.isViewingChangeComplexSchedulePage = isViewing);
  
      combineLatest([
        this.onsiteAuthService.hasScheduleAdminAccess$(),
        this.onsiteAuthService.hasParkingAdminAccess$(),
        this.onsiteAuthService.hasWorkspaceAdminAccess$()
      ]).subscribe(([scheduleAdminAccess, parkingAdminAccess, workspaceAdminAccess]) => {
        this.isDashboardAdminVisible = scheduleAdminAccess || parkingAdminAccess || workspaceAdminAccess;
      });

      // @ts-ignore
      window['launchKitStaticComponentsConfig'] = {
        footerBasicInternal: {
          target: '#launchKitFooter',
        },
      };

      const scriptId = 'launchKitComponentLoaderScriptFooter';
      if (!document.querySelector(`#${scriptId}`)) {
        const script = this.renderer.createElement('script');
        this.renderer.setAttribute(script, 'id', scriptId);
        this.renderer.setAttribute(script, 'src', 'https://cdn-internal.webcms.foc.zone/launchkit/components/loader.min.js');
        this.renderer.setAttribute(script, 'data-lksc-environment', 'prod');
        this.renderer.setAttribute(script, 'data-lksc-components', 'footerBasicInternal_RocketEnterpriseTech');
        this.renderer.appendChild(document.body, script);
    }
  }
}
